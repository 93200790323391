<template>
  <div class="specific-item">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select
            style="width: 88px"
            v-model="currentPlatform"
            @change="changePlatform"
            filterable
          >
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 108px" v-model="currentDate" filterable>
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.brand')">
          <el-select
            style="width: 128px"
            v-model="currentBrandId"
            clearable
            filterable
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('tagger.itemType')">
          <el-select
            style="width: 128px"
            v-model="currentItem"
            clearable
            filterable
          >
            <el-option
              v-for="item in itemList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('tagger.specificItem')"
        >
          <el-select
            style="width: 148px"
            v-model="currentSpecificItem"
            clearable
            filterable
          >
            <el-option
              v-for="item in specificItemList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-button
          size="small"
          class="excel-dl-btn"
          icon="el-icon-download"
          @click="downloadExcel"
          :disabled="!dateList || dateList.length === 0"
          >{{ $t("common.download") }}</el-button
        >
      </div>
    </div>
    <el-table :data="tableData">
      <el-table-column prop="desc" :label="$t('common.image')" min-width="50">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.url"
            style="height: 80px"
            :preview-src-list="[scope.row.url]"
            fit="contain"
          />
        </template>
      </el-table-column>
      <el-table-column prop="key" :label="$t('common.name')">
        <template slot-scope="scope">
          <router-link
            target="_blank"
            :to="{
              path: '/services/analysis/style-search',
              query: { q: scope.row.key, platform: currentPlatform },
            }"
            ><el-link type="primary">{{ scope.row.key }}</el-link>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="t1"
        :label="$t('tagger.category')"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="t2"
        :label="$t('tagger.itemType')"
        min-width="80"
      ></el-table-column>
      <el-table-column
        prop="t3"
        :label="$t('tagger.specificItem')"
        min-width="80"
      ></el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
  </div>
</template>

<script>
import {
  fetchRecords,
  fetchBest30SpecificItem,
  fetchBest30,
  fetchUserBrands,
  fetchUserItems,
  exportBest30Report,
} from "@/api/analysis";
import Pagination from "@/components/Pagination";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { ossRoot } from "@/utils/server";
import Guide from "@/components/Guide";

export default {
  components: { AiBreadcrumb, Pagination, Guide },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      currentDate: null,
      currentBrandId: null,
      currentItem: null,
      currentSpecificItem: null,
      tableData: [],
      dateList: [],
      brandList: [],
      itemList: [],
      specificItemList: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
    };
  },
  created() {
    this.$store.dispatch("category/fresh").then(() => {
      this.resetItemList();
      this.fetchDates();
    });
  },
  methods: {
    resetItemList() {
      let t2Flatten = this.$store.getters.t2Flatten;
      this.itemList = [];
      t2Flatten &&
        t2Flatten.forEach((t) => {
          t.disabled = true;
          this.itemList.push(t);
        });
    },
    fetchDates() {
      this.dateList = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.dateList.push({
                value: e.date,
                label: e.linkDate,
              });
            });
          if (this.dateList && this.dateList.length > 0) {
            this.currentDate = this.dateList[0].value;
          }
        }
      });
    },
    fetchBrands() {
      this.currentBrandId = null;
      this.brandList = [];
      this.currentSpecificItem = null;
      this.specificItemList = [];
      fetchUserBrands({
        dates: this.currentDate,
        platform: this.currentPlatform,
      }).then((response) => {
        if (response.success) {
          this.brandList =
            response.result && response.result.items
              ? response.result.items
              : [];
          if (this.brandList && this.brandList.length > 0) {
            this.currentBrandId = this.brandList[0].id;
          }
        }
      });
    },
    fetchItems() {
      this.currentItem = null;
      this.resetItemList();
      this.currentSpecificItem = null;
      this.specificItemList = [];
      fetchUserItems({ dates: this.currentDate }).then((response) => {
        if (response.success && response.result) {
          this.itemList.forEach((e, i) => {
            let disabled = true;
            for (let j = 0; j < response.result.length; j++) {
              const r = response.result[j];
              if (e.value === r) {
                disabled = false;
                break;
              }
            }
            e.disabled = disabled;
            this.$set(this.itemList, i, e);
            if (!disabled && !this.item) {
              this.item = e.value;
            }
          });
        }
      });
    },
    fetchSpecificItems() {
      this.currentSpecificItem = null;
      this.specificItemList = [];
      fetchBest30SpecificItem({
        period: "week",
        platform: this.currentPlatform,
        date: this.currentDate,
        brandId: this.currentBrandId,
        t2: this.currentItem,
      }).then((response) => {
        if (response.success) {
          this.specificItemList =
            response.result && response.result.items
              ? response.result.items
              : [];
          if (this.specificItemList && this.specificItemList.length > 0) {
            this.currentSpecificItem = this.specificItemList[0].id;
          }
        }
      });
    },
    fetchData() {
      fetchBest30({
        ...this.listQuery,
        period: "week",
        platform: this.currentPlatform,
        date: this.currentDate,
        brandId: this.currentBrandId,
        t2: this.currentItem,
        t3: this.currentSpecificItem,
      }).then((response) => {
        if (response.success) {
          this.tableData =
            response.result && response.result.items
              ? response.result.items
              : [];
          this.total = response.result.totalCount;
        }
      });
    },
    downloadExcel() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      exportBest30Report({
        period: "week",
        platform: this.currentPlatform,
        date: this.currentDate,
        brandId: this.currentBrandId,
        t2: this.currentItem,
        t3: this.currentSpecificItem,
      }).then((response) => {
        if (response.result && response.result.fileUrl !== "") {
          loading.close();
          clearInterval(this.timer);
          var a = document.createElement("a");
          a.href = ossRoot + "/" + response.result.fileUrl;
          a.download = "specific-item-" + Date.now() + ".xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        } else {
          this.timer = setTimeout(this.downloadExcel, 4000);
        }
      });
    },
    changePlatform() {
      this.fetchDates();
    },
  },
  watch: {
    currentDate() {
      this.fetchBrands();
      this.fetchItems();
      this.fetchData();
    },
    currentBrandId() {
      this.fetchSpecificItems();
      this.fetchData();
    },
    currentItem() {
      this.fetchSpecificItems();
      this.fetchData();
    },
    currentSpecificItem() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.specific-item {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }
  }
}
</style>